import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import { Section } from 'components/Section'
import { Flex } from 'components/Grid'
import { Resource } from 'components/Resources'
import { HTMLContent } from 'components/Content'

export const ResourcesIndexPageTemplate = ({ title, data }) => (
  <Section title={title}>
    <Flex flexDirection="column">
      {data &&
        data.map(resource => (
          <Resource
            key={resource.id}
            data={resource}
            contentComponent={HTMLContent}
          />
        ))}
    </Flex>
  </Section>
)

ResourcesIndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

const ResourcesIndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const { footerData = {}, headerData = {} } = data

  const resources = edges.map(edge => edge.node)
  const title = 'Ресурси'

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={title} />
      <ResourcesIndexPageTemplate title={title} data={resources} />
    </Layout>
  )
}

ResourcesIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ResourcesIndexPage

export const pageQuery = graphql`
  query ResourcesIndexPageTemplate {
    ...LayoutFragment
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "resource" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 500, format: HTML)
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fixed(width: 340, height: 488) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            file {
              publicURL
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
  }
`
